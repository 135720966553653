// -------------------------------------------------------------------
//  top.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}

// ----------------------------------
//  .hero
// ----------------------------------
.sec-hero{
    position: relative;
    z-index: 2;
    border: 20px solid $WHITE_COLOR;
    border-top-width: 80px;
    height: 100vh;
    //overflow: hidden;
    @include media(tb) {
        border: none;
        height: 60vw;
    }
    @include media(sp) {
        //height: calc(100vh - 70px);
        height: calc(100vh - 70px);;
    }
    video{
        object-fit: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
    .swiper{
        margin-bottom: 0;
        @include media(tb) {
            height: 100%;
        }
        .swiper-pagination-clickable .swiper-pagination-bullet{
            background: $WHITE_COLOR;
            @include media(tb) {
                background: $BLACK_COLOR;
            }
            &.swiper-pagination-bullet-active{
                background: $BLUE_COLOR;
            }
        }
    }
    .swiper-container{
        @include media(tb) {
            height: 100%;
        }
    }
    .swiper-slide-inner{
        height: 100vh;
        display: flex;
        flex-direction: row-reverse;
        @include media(tb) {
            height: 100%;
            flex-direction: column;
        }
        .img{
            a{
                box-shadow: none;
                width: 100%;
                height: 100%;
                transition: opacity 0.5s ease;
                margin: 0;
                padding: 0;
                &:hover{
                    opacity: 1;
                }
            }
            width: 100%;
            max-width: inherit;
            height: 100%;
            max-height: inherit;
        }
    }
    .swiper-slide-active img,
    .swiper-slide-duplicate-active img,
    .swiper-slide-prev img{
        animation: zoomUp 10s linear 0s 1 normal both;  
    }
    @keyframes zoomUp {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.2);
      }
    }
/*
    .swiper-pagination{
        bottom: 20px;
        //left: 0;
        //right: auto;
        left: auto;
        right: 20px;
        @include media(tb) {
            width: 100vw;
            bottom: 10px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
*/
    .copy{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        @include flex_inner_center;

        .catch{
            color: $WHITE_COLOR;
            text-align: center;
            font-size: 2.4rem;
            @include media(hd) {
                font-size: 2.0rem;
            }
            @include media(tb) {
                font-size: 1.8rem;
            }
            @include media(sp) {
                font-size: 1.8rem;
            }
        }
        .company-name{
            position: absolute;
            color: $WHITE_COLOR;
            font-family: $COPPERPLATE;
            bottom: -0.3em;
            line-height: 1;
            font-size: 10.0rem;
            @include media(hd) {
                font-size: 7.0rem;
            }
            @include media(tb) {
                font-size: 5.0rem;
            }
            @include media(sp) {
                font-size: 3.0rem;
            }
        }
    }
    .scrolldown{
        display: none;
        position:absolute;
        left: 40px;
        bottom:10px;
        height:80px;
        z-index: 3;
        a{
            color: $BLACK_COLOR;
        }
        @include media(tb) {
            display: none;
        }
    }
    .scrolldown span{
        position: absolute;
        left: -35px;
        top: 10px;
        color: $BLACK_COLOR;;
        font-size: 1.6rem;
        //font-family: $SERIF;
        transform: rotate(-90deg);
    }
    .scrolldown::before{
        content: "";
        position: absolute;
        top: 0;
        width: 2px;
        height: 90px;
        background: #CCC;
        opacity:1;
    }
    .scrolldown::after{
        content: "";
        position: absolute;
        top: 0;
        width: 2px;
        height: 60px;
        background: $BLACK_COLOR;
        animation: pathmove 1.4s ease-in-out infinite;
        opacity:0;
    }

    @keyframes pathmove{
        0%{
            height:0;
            top:0;
            opacity: 0;
        }
        30%{
            height:30px;
            opacity: 1;
        }
        100%{
            height:0;
            top:80px;
            opacity: 0;
        }
    }

    .hero-topics{
        position: absolute;
        bottom: 20px;
        left: auto;
        right: -520px;
        padding: 10px;
        background: $BLACK_COLOR;
        color: $WHITE_COLOR;
        display: flex;
        max-width: 500px;
        transition: right 0.5s ease;
        &.is-show{
            right: -20px;
        }
        @include media(tb) {
            max-width: 500px;
            right: -500px;
            &.is-show{
                right: 0px;
            }
        }
        @include media(sp) {
            max-width: 320px;
            right: -320px;
            bottom: 60px;
            &.is-show{
                right: 0px;
            }
        }
        .hero-topics-ttl{
            font-size: 1.3rem;
            min-height: 30px;
            line-height: 30px;
            display: flex;
            align-items: center;
            @include media(sp) {
                font-size: 1.2rem;
            }
        }
        .hero-topics-list{
            margin-left: 20px;
            font-size: 1.3rem;
            min-height: 30px;
            line-height: 1.2;
            @include media(sp) {
                font-size: 1.2rem;
            }
            a{
                color: $WHITE_COLOR;
                display: block;
                padding-right: 20px;
                position: relative;
                min-height: 30px;
                display: flex;
                align-items: center;
                &:after{
                    content: '\f054';
                    font-family: $FONT_AWESOME;
                    position: absolute;
                    top: 50%;
                    right: 0px;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

// ----------------------------------
//  .business
// ----------------------------------
.sec-business {
    @include media(sp) {
        margin-bottom: 90px;
    }
    .bg{
        overflow: visible;
    }
    .content-inner{
        position: relative;
        z-index: 1;
        padding-top: 20%;
        padding-bottom: 20%;
        padding-left: 10%;
        @include media(sp) {
            padding-left: 0%;
            padding-top: 90%;
            padding-bottom: 0%;
        }
    }
    .img-circle{
        mix-blend-mode: multiply;
        position: absolute;
        border-radius: 50%;
        overflow: hidden;
        &.img-circle-1{
            width: 25%;
            left: -10%;
            top: 2%;
            @include media(sp) {
                width: 40%;
                left: -10%;
                top: 5%;
            }
        }
        &.img-circle-2{
            width: 23%;
            left: 68%;
            top: 10%;
            z-index: 1;
            @include media(hd) {
                left: 78%;
            }
            @include media(sp) {
                width: 40%;
                left: 70%;
                top: 3%;
            }
        }
        &.img-circle-3{
            width: 28%;
            left: 58%;
            top: 45%;
            @include media(hd) {
                left: 68%;
            }
            @include media(sp) {
                top: auto;
                width: 55%;
                left: 10%;
                top: 15%;
            }
        }
        &.img-circle-4{
            width: 25%;
            left: -5%;
            bottom: -12%;
            @include media(sp) {
                width: 33%;
                left: -15%;
                top: 33%;
                bottom: auto;
            }
        }
        &.img-circle-5{
            width: 30%;
            right: -5%;
            bottom: -35%;
            @include media(sp) {
                width: 45%;
                right: -5%;
                bottom: auto;
                top: 24%;
            }
        }
    }
}

// ----------------------------------
//  .about
// ----------------------------------
.sec-about {
    .btn-container{
        @include flex_space_between;
        .btn-extend-wrap{
            width: calc(50% - 10px);
            margin-bottom: 20px;
            @include media(sp) {
                width: 100%;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }
    .content-container{
        width: 65%;
        @include media(tb) {
            width: 70%;
        }
        @include media(sp) {
            width: 100%;
        }
    }
}
// ----------------------------------
//  .group
// ----------------------------------
.sec-group {
    .sec-ttl{
        .en{
            span{
                @include media(wide) {
                    font-size: 0.85em;
                }
                @include media(hd) {
                    font-size: 0.72em;
                }
                @include media(tb) {
                    font-size: 1.0em;
                }
            }
        }
    }
    .content-container{
        width: 65%;
        @include media(tb) {
            width: 70%;
        }
        @include media(sp) {
            width: 100%;
        }
    }
}
// ----------------------------------
//  .ir
// ----------------------------------
.sec-ir {
    .inner{
        max-width: $SITE_WIDTH2;
    }
}
// ----------------------------------
//  .news
// ----------------------------------
.sec-news {
    background: $BASE_BG_COLOR;
    padding: 80px 0;
    .inner{
        max-width: $SITE_WIDTH2;
    }
    @include media(tb) {
        padding: 40px 0;
    }
    @include media(sp) {
        padding: 40px 0;
    }
    .btn-extend-wrap{
        margin: 40px auto 0;
        @include media(tb) {
            margin: 20px auto 0;
        }
    }
}











.TitleTextAnime span{
    opacity: 0;
    display: inline-block;
}
.TitleTextAnime.appeartext span{ animation:text_anime_on_ttl 1s ease-out forwards; }
.CatchTextAnime span{
    opacity: 0;
    display: inline-block;
}
.CatchTextAnime.appeartext span{ animation:catch_anime_on_ttl 1s ease-out forwards; }
@keyframes text_anime_on_ttl {
  0% {
    opacity:0;
    transform: translateX(-10px);
  }
  100% {
    opacity:1;
    transform: translateX(0px);
  }
}
@keyframes catch_anime_on_ttl {
  0% {
    opacity:0;
    transform: translateX(-20px);
  }
  100% {
    opacity:1;
    transform: translateX(0px);
  }
}




