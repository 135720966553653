// ----------------------------------
//  form
// ----------------------------------
.validation-form{
    //max-width: 600px;
    //margin: 0 auto;
}
.form-list{
    display: flex;
    flex-wrap: wrap;
    dt{
        color: $BLACK_COLOR;
        position: relative;
        width: 30%;
        padding: 20px 20px;
        font-weight: bold;
        @include media(sp){
            width: 100%;
            padding: 10px 0px 5px;
        }
        .ico-required:after{
            content: "※必須";
            @include fontsize_small;
            display: inline-block;
            color: $RED_COLOR;
            margin-left: 10px;
        }
    }
    dd{
        width: 70%;
        padding: 20px 20px;
        @include media(sp){
            width: 100%;
            padding: 0 0 10px;
        }
        label.error{
            color: $RED_COLOR;
        }
    }
    input[type=text],
    input[type=email],
    select{
        outline: 0;
        width: 100%;
        padding: 10px;
        border: 1px solid $LIGHT_GRAY_COLOR;
        background: $OFF_WHITE_COLOR;

        &:focus{
            border-color: $GRAY_COLOR;
        }

        &.zip1{
            width: 45px;
            margin-bottom: 10px;
        }
        &.zip2{
            width: 60px;
            margin-bottom: 10px;
        }
    }
    textarea{
        outline: 0;
        width: 100%;
        height: 160px;
        padding: 1.0rem;
        border: 1px solid $LIGHT_GRAY_COLOR;
        background: $OFF_WHITE_COLOR;

        &:focus{
            border-color: $GRAY_COLOR;
        }
    }
}

// ----------------------------------
//  .label-checkbox
// ----------------------------------
.checkbox-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.label-checkbox{
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 50%;
    @include media(tb){
        width: 100%;
    }

    input[type="checkbox"]{
        display: none;

        &:checked{
            + .item-name{
                &:before{
                    //background-color: $LIGHT_GRAY_COLOR;
                    border-color: $BLACK_COLOR;
                }

                &:after{
                    border-color: $BLACK_COLOR;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .item-name{
        position: relative;
        padding-left: 2.0rem;

        &:before,&:after{
            content: "";
            position: absolute;
        }

        &:before{
            width: 1.4rem;
            height: 1.4rem;
            border: 1px solid $BLACK_COLOR;
            background-color: $WHITE_COLOR;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.1s linear;
        }

        &:after{
            width: 0.9rem;
            height: 0.4rem;
            border-left: 2px solid $WHITE_COLOR;
            border-bottom: 2px solid $WHITE_COLOR;
            left: 3px;
            top: 50%;
            margin-top: -4px;
            transition: all 0.2s linear;
        }
    }
}


// ----------------------------------
//  .label-radio
// ----------------------------------
.label-radio{
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-right: 1rem;

    input[type="radio"]{
        display: none;

        &:checked{
            + .item-name{
                &:before{
                    border-color: $BLACK_COLOR;
                }
                &:after{
                    background-color: $BLACK_COLOR;
                }
            }
        }
    }

    .item-name{
        position: relative;
        padding-left: 2.0rem;

        &:before, &:after{
            content: "";
            position: absolute;
            box-sizing: border-box;
            transition: all 0.2s linear;
        }

        &:before{
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            border: 1px solid $BLACK_COLOR;
            background-color: $WHITE_COLOR;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after{
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            left: 4px;
            top: 50%;
            transform: translateY(-50%);
            bottom: 0;
        }
    }
}


// ----------------------------------
//  .select-wrap
// ----------------------------------
.select-wrap{
    position: relative;

    &:after{
        font-family: $FONT_AWESOME;
        content: "\f078";
        font-size: 1.4rem;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        pointer-events: none;
        @include flex_inner_center;
    }

    select{
        width: 100%;
        cursor: pointer;
        text-overflow: ellipsis;
        padding-right: 1.5rem;

        &::-ms-expand {
            display: none;
        }
    }
}

