// -------------------------------------------------------------------
//  contact.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}



// ----------------------------------
//  contact
// ----------------------------------
body[class^="contact"]{
    .sec-contact{
        a{
            text-decoration: underline;
        }
        .content-inner{
            max-width: 1000px;
            margin: 0 auto;
        }
        .sec-contact-lead{
            margin-bottom: 80px;
            @include media(tb) {
                margin-bottom: 40px;
            }
            @include media(sp) {
                margin-bottom: 20px;
            }
            strong{
                color: $RED_COLOR;
            }
        }
        .sec-ttl{
            text-align: center;
            .jp{
                @include fontsize_large;
                @include media(sp) {
                    line-height: 1.4;
                }
            }
        }
        .txt-des{
            @include media(sp) {
                br{
                    display: none;
                }
            }
            a{
                text-decoration: underline;
            }
        }

        .contact-list{
        }
        .contact-item{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0px;
            }
            a{
                text-decoration: none;
                display: block;
                background: $OFF_WHITE_COLOR;
                padding: 30px 20px;
                color: $BLACK_COLOR;
                position: relative;
                @include media(sp) {
                    padding: 20px 30px 20px 20px;
                }
                &:after{
                    font-family: $FONT_AWESOME;
                    content: '\f054';
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &[target="_blank"]:after{
/*
                    content: "\f08e";
                    right: 10px;
*/
                    content: "";
                    width: 20px;
                    height: 20px;
                }
            }
            .contact-ttl{
                font-size: 1.2em;
                line-height: 1.2;
            }
            .contact-des{
                margin-top: 5px;
                @include fontsize_small;
            }
        }
        .area-wrap{
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            .area-question{
                margin-right: 20px;
                @include fontsize_small;
                //color: $RED_COLOR;
                @include media(tb) {
                    width: 100%;
                }
            }
            .label-radio{
                @include fontsize_small;
            }
        }
        .btn-wrap{
            margin-top: 20px;
            display: flex;
            justify-content: center;
        }
        .caution{
            margin-top: 80px;
            @include media(tb) {
                margin-top: 40px;
            }
            @include media(sp) {
                margin-top: 20px;
            }
            a:before{
                display: inline-block;
                font-family: $FONT_AWESOME;
                content: "\f0a9";
                font-weight: 600;
                margin-right: 10px;
            }
        }
        .form-result{
            h3{
                @include fontsize_large;
                font-weight: 400;
                margin-bottom: 40px;
                @include media(tb) {
                    margin-top: 20px;
                }
            }
        }
    }
}

