// ----------------------------------
//  tab-panel
// ----------------------------------
.tab-panel{
}
.tab-list{
    display: flex;
    justify-content: space-between;
    //border-bottom: 2px solid $LINK_COLOR;
}
.tab-item{
    width: calc(20% - 1rem);
    @include media(sp){
        width: calc(20% - 0.5rem);
    }
    a{
        height: 100%;
        padding: 1rem 0.5rem;
        border: 1px solid $BORDER_COLOR;
        //border-bottom: none;
        //border-radius: 5px 5px 0 0 / 5px 5px 0 0;
        @include flex_inner_center;
        color: $BASE_TEXT_COLOR;
        &.is-selected{
            background: $BLACK_COLOR;
            color: $WHITE_COLOR;
        }
    }
}
.panel-list{
}
.panel-item{
    display: none;
}
