// -------------------------------------------------------------------
//  information.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}



// ----------------------------------
//  information
// ----------------------------------
.information{
    .sec-news-list{
        .content-inner{
            //max-width: 1000px;
            //margin: 0 auto;
        }
        .tab-list{
            justify-content: center;
            max-width: 1000px;
            margin: 0 auto;
        }
        .tab-item{
            width: calc(33.33%);
        }
        .ir-list{
            padding: 40px 0;
            @include flex_space_between;
            &:after{
                content: "";
                display: block;
                width: calc(33.33% - 20px);
            }
            @include media(tb) {
                padding: 20px 0;
            }
            @include media(sp) {
                padding: 0px 0;
            }
        }
        .ir-item{
            width: calc(33.33% - 20px);
            margin-bottom: 60px;
            @include media(tb) {
                width: calc(33.33% - 10px);
                margin-bottom: 40px;
            }
            @include media(sp) {
                width: calc(50% - 5px);
                margin-bottom: 40px;
            }
            a{
                flex-direction: column;
                transition: all 0.5s ease;
                padding: 0;
                &.ico-pdf{
                    &:after{
                        content: "";
                    }
                }
                &:hover{
                    background: none;
                    opacity: 0.5;
                }
            }
            .ir-tumnb{
                height: 300px;
                position: relative;
                background: $LIGHT_GRAY_COLOR;
                @include flex_inner_center;
                .logo{
                    max-width: 60%;
                    opacity: 0.6;
                }
                @include media(hd) {
                    height: 200px;
                }
                @include media(tb) {
                    height: 150px;
                }
                @include media(sp) {
                    height: 120px;
                }
            }
            .ir-date{
                margin-top: 10px;
                @include fontsize_small;
                height: 20px;
                line-height: 20px;
            }
            .ir-ttl{
                width: 100%;
                flex-direction: column;
                align-items: flex-end;
                margin-top: -25px;
                @include media(sp) {
                    padding-right: 0;
                }
            }
            .ir-category{
                margin-right: 0;
                @include media(sp) {
                    position: relative;
                    top: 0;
                    right: 0;
                }
            }
            .ir-txt{
                @include fontsize_small;
                margin-top: 5px;
                width: 100%;
            }
        }
    }
}

// ----------------------------------
//  information-detail
// ----------------------------------
.information-detail{
    .breadcrumb{
        display: none;
    }
    .content-inner{
        max-width: 1000px;
        margin: 0 auto;
    }
    .news-detail-header{
        @include fontsize_small;
        margin-bottom: 20px;
        @include media(tb){
            margin-bottom: 10px;
        }
        @include media(sp){
            margin-bottom: 10px;
        }
        .ir-date{
            font-weight: bold;
            font-size: 1.1em;
        }
        .ir-category{
            display: inline-block;
            margin-left: 20px;
            background: $BLACK_COLOR;
            color: $WHITE_COLOR;
            text-align: center;
            padding: 0 20px;
        }
    }
    .news-detail-sns{
        text-align: right;
        @include media(sp){
            margin-bottom: 10px;
        }
    }
    .news-detail-ttl{
        line-height: 1.4;
        margin-bottom: 40px;
        @include media(tb){
            margin-bottom: 20px;
        }
        @include media(sp){
            margin-bottom: 20px;
        }
    }
    .news-deital-body{
        margin-bottom: 80px;
        @include media(tb){
            margin-bottom: 40px;
        }
        @include media(sp){
            margin-bottom: 20px;
        }
        img{
            height: auto;
        }
    }
    .news-detail-footer{
        .btn-black{
            margin: 0 auto;
        }
    }

}
