// ----------------------------------
//  drawer_menu
// ----------------------------------
/* ドロワーメニュー */
#drawer_menu{
    display: flex;
    align-items: center;
    width:100%;
    height:calc(100vh - 80px);
    z-index:9999;
    color:#fff;
    overflow:auto;
    overflow-x:hidden;
    opacity:0;
    pointer-events:none;
    position:fixed;
    top:120px;
    left:0px;
    transition: all 0.7s cubic-bezier(0.22, 1, 0.36, 1) 0s;
    background: $BLACK_COLOR;
    background-size: cover;
    @include media(pc){
        display: none;
    }
    @include media(tb){
        height: calc(100vh - 70px) !important;
    }
    .simplebar-content-wrapper {
        display:flex;
        flex-wrap:wrap;
        align-items:center;
    }
    #drawer_menu_inner{
        a{
            color: $WHITE_COLOR;
        }
        .inner{
            padding: 0;
        }
        padding: 0px 80px;
        max-height: calc(100vh - 160px);
        overflow: auto;
        @include media(tb) {
            padding: 0px 40px;
        }
        @include media(sp) {
            padding: 0px 20px;
            max-height: calc(100vh - 120px);
        }

        .inner{
            max-width: 1000px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
        h2{
            display: none;
            width: 300px;
            font-family: $COPPERPLATE;
            font-weight: 400;
            color: $WHITE_COLOR;
            line-height: 1.2;
            font-size: 3.0rem;
            @include media(wide) {
                width: 350px;
                font-size: 3.0rem;
            }
            @include media(hd) {
                display: none;
                width: 100%;
                padding-bottom: 20px;
                margin-bottom: 40px;
                font-size: 2.6rem;
                border-bottom: 1px solid $WHITE_COLOR;
                br{
                    display: none;
                }
            }
            @include media(sp) {
                display: none;
                font-size: 2.4rem;
            }
        }
        .menu-wrapper{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            @include media(tb) {
                justify-content: center;
            }
            .menu-wrapper-left{
                margin-right: 80px;
                @include media(sp) {
                    margin-right: 0px;
                    width: 100%;
                }
            }
            .menu-wrapper-right{
                @include media(sp) {
                    width: 100%;
                }
            }
            .menu-list{
            }
            .launch-site{
                margin-top: 90px;
                @include media(sp) {
                    margin-top: 0px;
                }
                .menu-item{
                    margin-top: -5px;
                    font-size: 1.6rem;
                    @include media(sp) {
                        margin-top: 0px;
                        font-size: 1.4rem;
                    }
                    & > a{
                        //color: #C3C8CE;
                        color: $WHITE_COLOR;
                        &[target="_blank"]{
                            &:after{
                                display: none;
                                //font-size: 1.0rem;
                            }
                            .jp:after{
                                content: "";
                                width: 20px;
                                height: 20px;
                                display: inline-block;
                                //background: url(../images/ico_window_gray.svg);
                                background: url(../images/ico_window_wh.svg);
                                background-size: cover;
                                vertical-align: middle;
                                margin-left: 10px;
                                @include media(sp) {
                                    position: absolute;
                                    right: 4px;
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .menu-item{
                font-family: $COPPERPLATE;
                font-size: 2.0rem;
                margin-bottom: 15px;
                @include media(tb) {
                    font-size: 1.6rem;
                }
                @include media(sp) {
                    font-size: 1.4rem;
                    margin-bottom: 0px;
                }

                &:last-child{
                    margin-bottom: 0;
                    @include media(sp) {
                        margin-bottom: 0px;
                    }
                }
                & > a{
                    display: block;
                    margin-bottom: 20px;
                    position: relative;
                    color: $WHITE_COLOR;
                    padding-right: 20px;
                    line-height: 1;
                    &[target="_blank"]{
                        &:after{
                            display: none;
                            //font-size: 1.0rem;
                        }
                        .jp:after{
                            content: "";
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url(../images/ico_window_wh.svg);
                            background-size: cover;
                            vertical-align: middle;
                            margin-left: 10px;
                            @include media(sp) {
                                position: absolute;
                                right: 4px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                    @include media(sp) {
                        margin-bottom: 0px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-right: 20px;
                        border-bottom: 1px dotted $LIGHT_GRAY_COLOR;
                        &:after{
                            font-family: $FONT_AWESOME;
                            font-size: 1.0rem;
                            content: '\f054';
                            display: block;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    span.jp{
                        display: block;
                    }
                    span.en{
                        display: none;
                    }
                }
            }
            .sub-menu-list{
                padding-left: 0px;
                margin-top: -5px;
                font-size: 1.4rem;
                @include media(sp) {
                    margin-top: 10px;
                }
            }
            .sub-menu-item{
                margin-bottom: 10px;
                @include media(tb) {
                    font-size: 1.4rem;
                    padding-left: 0px;
                }
                @include media(sp) {
                }
                a{
                    padding-bottom: 5px;
                    padding-left: 10px;
                    display: block;
                    position: relative;
                    line-height: 1;
                    &[target="_blank"]{
                        &:after{
                            display: none;
                            //font-size: 1.0rem;
                        }
                        .jp:after{
                            content: "";
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url(../images/ico_window_wh.svg);
                            background-size: cover;
                            vertical-align: middle;
                            margin-left: 10px;
                            @include media(sp) {
                                position: absolute;
                                right: 4px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                    @include media(sp) {
                        margin-bottom: 0px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        padding-right: 20px;
                        &:after{
                            font-family: $FONT_AWESOME;
                            font-size: 1.0rem;
                            content: '\f054';
                            display: block;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    span.jp{
                        display: block;
                    }
                    span.en{
                        display: none;
                    }
                    &.is-disabled{
                        pointer-events: none;
                        opacity: 0.5;
                    }
                }
            }
            .second-menu-list{
                padding-left: 0px;
                margin-top: 40px;
                font-size: 1.4rem;
                @include media(sp) {
                    margin-top: 10px;
                }
            }
            .second-menu-item{
                margin-bottom: 10px;
                @include media(tb) {
                    font-size: 1.4rem;
                    padding-left: 0px;
                }
                @include media(sp) {
                }
                a{
                    padding-bottom: 5px;
                    display: block;
                    position: relative;
                    line-height: 1;
                    &[target="_blank"]{
                        &:after{
                            display: none;
                            //font-size: 1.0rem;
                        }
                        .jp:after{
                            content: "";
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url(../images/ico_window_wh.svg);
                            background-size: cover;
                            vertical-align: middle;
                            margin-left: 10px;
                        }
                    }
                    @include media(sp) {
                        display: block;
                        margin-bottom: 0px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-right: 20px;
                        border-bottom: 1px dotted $LIGHT_GRAY_COLOR;
                        &:after{
                            font-family: $FONT_AWESOME;
                            font-size: 1.0rem;
                            content: '\f054';
                            display: block;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    span.jp{
                        display: block;
                    }
                    span.en{
                        display: none;
                    }
                    &.is-disabled{
                        pointer-events: none;
                        opacity: 0.5;
                    }
                }
            }
        }
        .sns-wrap{
            display: flex;
            margin-bottom: 20px;
            @include media(sp) {
                flex-direction: column;
                justify-content: flex-start;
                padding: 20px 0;
            }
        }
        .sns-sub-ttl{
            font-family: $COPPERPLATE;
            @include media(sp) {
                font-size: 1.6rem;
                text-align: center;
                margin-bottom: 5px;
            }
        }
        .sns-list{
            display: flex;
            margin-top: 40px;
            @include media(sp) {
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
        .sns-item{
            //font-size: 1.6rem;
            margin-right: 20px;
            @include media(tb) {
                margin-left: 10px;
            }
            @include media(tb) {
                margin-left: 10px;
                margin-right: 10px;
            }
            i{
                font-size: 3.0rem;
                @include media(hd) {
                    font-size: 2.6rem;
                }
                @include media(tb) {
                    font-size: 2.4rem;
                }
            }
            a{
                color: $WHITE_COLOR;
            }
            a:after{
                display: none;
            }
        }
        .menu-footer{
            margin-top: 80px;
            width: 100%;
            @include flex_space_between;
            justify-content: flex-end;
            @include media(hd) {
                margin-top: 60px;
            }
            @include media(sp) {
                margin-top: 20px;
                justify-content: center;
                flex-direction: column;
            }
            .sns-wrap{
                display: flex;
                margin-bottom: 20px;
                @include media(sp) {
                    flex-direction: column;
                    justify-content: flex-start;
                    padding: 20px 0;
                }
            }
            .sns-sub-ttl{
                font-family: $COPPERPLATE;
                @include media(sp) {
                    font-size: 1.6rem;
                    text-align: center;
                    margin-bottom: 5px;
                }
            }
            .sns-list{
                display: flex;
                justify-content: center;
            }
            .sns-item{
                //font-size: 1.6rem;
                margin-left: 20px;
                @include media(tb) {
                    margin-left: 10px;
                }
                @include media(tb) {
                    margin-left: 10px;
                    margin-right: 10px;
                }
                i{
                    font-size: 3.0rem;
                    @include media(hd) {
                        font-size: 2.6rem;
                    }
                    @include media(tb) {
                        font-size: 2.4rem;
                    }
                }
                a{
                    color: $WHITE_COLOR;
                }
                a:after{
                    display: none;
                }
            }
            .link-list{
                font-size: 1.6rem;
                @include media(hd) {
                    font-size: 1.4rem;
                }
                @include media(sp) {
                    font-size: 1.4rem;
                    text-align: center;
                }
            }
        }
    }
}
html.open_menu{
    #drawer_menu {
        opacity:1;
        pointer-events:initial;
        top: $HEADER_HEIGHT_PC;
        position: fixed;
        @include media(hd) {
            top: $HEADER_HEIGHT_TB;
        }
        @include media(sp) {
            top: $HEADER_HEIGHT_SP;
        }
    }
    #drawer_menu .simplebar-content-wrapper {
        display:flex;
        flex-wrap:wrap;
        align-items:center;
    }
    #drawer_menu_inner {
        position:relative;
        width:100%;
        padding-bottom:130px;
    }
    .header#header {
        background: $WHITE_COLOR !important;
    }
}
