// -------------------------------------------------------------------
//  anchor.scss
// -------------------------------------------------------------------
.nav-anchor{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 101;
    @include media(tb){
        top: $HEADER_HEIGHT_SP;
        transform: translateY(0%);
    }
    .inner{
        padding: 0;
        padding-right: 20px;
        @include media(tb){
            padding-right: 0;
        }
    }
    .nav-history-list{
        background: rgba(255,255,255,0.8);
        padding: 10px 0;
        opacity: 0;
        transition: opacity 0.5s ease;
        &.appear{
            opacity: 1;
        }
    }
    .nav-history-item{
        font-family: $COPPERPLATE;
        @include fontsize_small;
        margin-bottom: 5px;
    }
    .btn-anchor{
        display: block;
        position: relative;
        padding: 0 10px 0 25px;
        //background: rgba(255,255,255,0.8);
        //border-radius: 20px;
        @include media(sp){
            font-size: 1.2em;
            padding: 0 10px 0 20px;
        }
    }
    .active{
        .btn-anchor{
            color: $RED_COLOR;
            &:before{
                font-family: $FONT_AWESOME;
                content: "\f061";
                position: absolute;
                left: 10px;
                font-size: 1.2rem;
                top: 50%;
                transform: translateY(-52%);
                animation: arrowmove 1.5s ease-in-out infinite;
                @include media(sp){
                    font-size: 1.0rem;
                }
            }
        }
    }
}
@keyframes arrowmove{
    0%{left: 5px;}
    50%{left: 8px;}
    100%{left: 5px;}
}
