// -------------------------------------------------------------------
//  sitepolicy.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}

// ----------------------------------
//  sitepolicy
// ----------------------------------
.sitepolicy{
    .content-inner{
        max-width: 1000px;
        margin: 0 auto;
    }
    .sec-sub-sub-content{
        margin-bottom: 40px;
        &:last-child{
            margin-bottom: 0px;
        }
        @include media(tb) {
            margin-bottom: 30px;
        }
        @include media(sp) {
            margin-bottom: 20px;
        }
    }
}
