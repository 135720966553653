// -------------------------------------------------------------------
//  businessdomain.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}

// ----------------------------------
//  businessdomain
// ----------------------------------
.businessdomain{
    .sec-page-ttl{
    }
    .breadcrumb{
        margin-bottom: 20px;
    }
    .sec-strategy1{
        margin-bottom: 0;
        .inner{
            max-width: 1800px;
        }
        background: $OFF_WHITE_COLOR;
        @include media(tb) {
            background: $WHITE_COLOR;
        }
        .content-container{
            margin-top: 10%;
            margin-bottom: 15%;
            background: transparent;
            padding: 0;
            @include media(hd) {
            }
            @include media(tb) {
                margin-top: 10%;
                margin-bottom: 10%;
                padding: 20px;
                background: rgba(255, 255, 255, 0.8);
            }
        }
        .sec-ttl{
            margin-bottom: 40px;
            @include media(hd) {
                margin-bottom: 20px;
            }
            @include media(tb) {
                margin-bottom: 20px;
            }
            @include media(sp) {
            }
            .en{
                span{
                    font-size: 0.59em;
                    @include media(wide) {
                        font-size: 0.48em;
                    }
                    @include media(hd) {
                        font-size: 0.42em;
                    }
                    @include media(tb) {
                        font-size: 0.45em;
                    }
                    @include media(sp) {
                        font-size: 0.5em;
                    }
                }
            }
            .jp{
                line-height: 1.6;
            }
        }
        .sec-sub-ttl{
            margin-bottom: 10px;
            @include media(sp) {
                margin-bottom: 10px;
            }
            span{
                display: block;
                font-size: 0.9em;
                @include media(hd) {
                    font-size: 0.8em;
                }
                @include media(sp) {
                    font-size: 1.0em;
                }
            }
        }
    }
    .sec-strategy2{
        position: relative;
        z-index: 10;
        font-family: $NOTO_SANS_JP;
        margin-top: -10%;
        margin-bottom: 0;
        @include media(wide) {
            margin-top: -10%;
        }
        @include media(hd) {
            margin-top: -10%;
        }
        @include media(tb) {
            margin-top: 5%;
            margin-bottom: 5%;
        }
        .business-container{
            max-width: 860px;
            margin: 0 auto;
            @include flex_space_between;
            position: relative;
            .border-wrap{
                position: absolute;
                width: 100%;
                span{
                    position: absolute;
                    display: block;
                    border-bottom: 2px dotted $GRAY_COLOR;
                    width: 120px;
                    left: 50%;
                    transform: translateX(-50%);
                    @include media(tb) {
                        width: 80px;
                    }
                    &.border1{
                        top: 160px;
                        @include media(tb) {
                            top: 130px;
                        }
                        @include media(sp) {
                            display: none;
                        }
                    }
                    &.border2{
                        top: 225px;
                        @include media(hd) {
                            top: 215px;
                        }
                        @include media(tb) {
                            top: 180px;
                        }
                        @include media(sp) {
                            display: none;
                        }
                    }
                    &.border3{
                        top: 290px;
                        @include media(hd) {
                            top: 270px;
                        }
                        @include media(tb) {
                            top: 240px;
                        }
                        @include media(sp) {
                            display: none;
                        }
                    }
                }
            }
        }
        .sec-bisiness{
            width: calc(50% - 20px);
            @include media(sp) {
                width: 100%;
            }
            .sec-bisiness-ttl{
                text-align: center;
                height: 40px;
                line-height: 40px;
            }
            .sec-bisiness-detail{
                background: $OFF_WHITE_COLOR;
                padding: 40px;
                border: 2px solid $BLACK_COLOR;
                @include media(tb) {
                    padding: 20px;
                    border: 1px solid $BLACK_COLOR;
                }
            }
            .sec-bisiness-sub-ttl{
                text-align: center;
                line-height: 1.2;
                margin-bottom: 20px;
                span{
                    display: block;
                }
                .sub{
                    @include fontsize_small;
                }
            }
            .strategy-list{
            }
            .strategy-item{
                @include fontsize_small;
                background: $WHITE_COLOR;
                padding: 10px 20px;
                border-radius: 20px;
                margin-bottom: 20px;
                text-align: center;
                &:last-child{
                    margin-bottom: 0;
                }
                @include media(hd) {
                    margin-bottom: 10px;
                }
            }
        }
        .sec-bisiness-restaurant{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @include media(sp) {
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 0px;
                padding-bottom: 20px;
            }
        }
    }
    .sec-strategy3{
        margin-top: -20%;
        //background: $OFF_WHITE_COLOR;
        @include media(wide) {
            margin-top: -25%;
        }
        @include media(hd) {
            margin-top: -40%;
        }
        @include media(tb) {
            margin-top: 0%;
            background: $WHITE_COLOR;
        }
        &:after{
            content: "";
            position: absolute;
            width: 100%;
            height: 90%;
            background: $OFF_WHITE_COLOR;
            top: 0;
            z-index: -1;
            @include media(wide) {
                height: 95%;
            }
            @include media(hd) {
                height: 100%;
            }
        }
        .bg{
            z-index: 2;
            @include media(hd) {
                padding-top: 30%;
            }
            @include media(tb) {
                padding-top: 0%;
            }
        }
        .inner{
            max-width: 1800px;
        }
        .content-container{
            margin-top: 25%;
            margin-bottom: 10%;
            background: transparent;
            padding: 0;
            position: relative;
            z-index: 3;
            @include media(wide) {
                margin-top: 35%;
            }
            @include media(hd) {
                margin-top: 50%;
            }
            @include media(tb) {
                margin-top: 10%;
                margin-bottom: 10%;
                padding: 20px;
                background: rgba(255, 255, 255, 0.8);
            }
        }
    }
    .sec-movie{
        .inner{
            max-width: 1000px;
        }
        .movie-wrap{
            position: relative;
            &:before{
                content:"";
                display: block;
                padding-top: 56.25%;
            }
            iframe{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        iframe{
            width: 100%;
            height: auto;
        }
    }
    .sec-restaurant,
    .sec-ed{
        .content-container{
            margin-top: 10%;
            margin-bottom: 10%;
            @include media(hd) {
                margin-top: 5%;
                margin-bottom: 5%;
            }
        }
        .sec-ttl{
            .en{
                line-height: 0.85;
                @include media(hd) {
                    line-height: 0.65;
                }
                @include media(sp) {
                    line-height: 0.85;
                }
                span{
                    font-size: 0.85em;
                    @include media(hd) {
                        font-size: 0.65em;
                    }
                    @include media(sp) {
                        font-size: 0.85em;
                    }
                }
            }
            .jp{
                margin-top: 20px;
                @include fontsize_large;
                font-family: $NOTO_SANS_JP;
                span{
                    @include media(hd) {
                        font-size: 0.8em;
                    }
                    @include media(sp) {
                        font-size: 1.0em;
                    }
                }
            }
        }
        .business-list{
            padding-left: 30px;
            @include media(sp) {
                padding-left: 20px;
            }
        }
        .business-item{
            list-style: disc outside;
        }
    }
    .sec-brands{
        .sec-ttl{
            text-align: center;
            .en{
                line-height: 0.7;
                span{
                    font-size: 0.7em;
                }
            }
        }
        .content-container{
            width: 100%;
        }
        .btn-extend-wrap{
            margin: 0 auto;
        }
    }
}
