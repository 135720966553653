// -------------------------------------------------------------------
//  footer.scss
// -------------------------------------------------------------------
@include media(tb){
}
@include media(sp){
}

.footer {
    margin-top: 0px;
    padding-top: 160px;
    @include media(tb){
        padding-top: 80px;
    }
    @include media(sp){
        padding-top: 40px;
    }
    .footer-navi{
        .inner{
            @include flex_space_between;
            align-items: center;
            padding: 0 80px;
            @include media(wide){
                padding: 0 60px;
            }
            @include media(hd){
                padding: 0 40px;
            }
            @include media(sp){
                padding: 0 20px;
            }
        }
    }
    .footer-navi-wrap{
        width: 550px;
        display: flex;
        flex-wrap: wrap;
        @include media(hd){
            width: 520px;
        }
        @include media(tb){
            width: 100%;
        }
        .navi-list{
            margin-right: 40px;
            &:last-child{
                margin-right: 0;
            }
            @include media(tb){
                width: calc(50% - 20px);
                margin-right: 0px;
            }
            @include media(sp){
                margin-right: 0px;
                width: 100%;
            }
        }
        .navi-item{
            //font-family: $OPEN_SANS;
            @include fontsize_small;
            margin-bottom: 20px;
            @include media(sp){
                margin-bottom: 0px;
            }
            & > a{
                font-weight: bold;
                font-size: 1.1em;
            }
            a{
                color: $BLACK_COLOR;
                @include media(sp){
                    position: relative;
                    display: block;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-right: 20px;
                    border-bottom: 1px dotted $GRAY_COLOR;
                    &:after{
                        font-family: $FONT_AWESOME;
                        content: '\f054';
                        display: block;
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &[target="_blank"]:after{
                        //content: "\f08e";
                        content: "";
                        right: 0;
                    }
                }
            }
        }
        .sub-navi-list{
            margin-top: 10px;
            margin-left: 20px;
            @include media(sp){
                margin-top: 0px;
            }
        }
        .sub-navi-item{
            margin-bottom: 10px;
            & > a{
                font-weight: 100;
            }
            a.is-disabled{
                pointer-events: none;
                opacity: 0.5;
            }
        }
        .sns-wrap{
            width: 100%;
            @include media(tb) {
                flex-wrap: wrap;
                margin-top: 40px;
                margin-bottom: 20px;
            }
        }
        .sns-list{
            display: flex;
            justify-content: left;
            @include media(tb) {
                justify-content: center;
            }
            @include media(sp) {
                flex-wrap: wrap;
            }
        }
        .sns-item{
            margin: 0 10px;
            
            @include media(sp) {
                margin: 0 10px;
            }
            i{
                //position: absolute;
                font-size: 3.0rem;
                //margin-right: 5px;
                vertical-align: bottom;
                left: 0;
                //top: -4px;
                @include media(tb) {
                    font-size: 4.0rem;
                }
                @include media(narrow) {
                    font-size: 3.5rem;
                }
            }
            a{
                color: $BLACK_COLOR;
                font-size: 1.6rem;
                position: relative;
                //padding-left: 35px;
            }
            a[target="_blank"]:after{
                display: none;
            }
        }
    }
    .footer-launch-wrap{
        width: calc(100% - 600px);
        @include media(hd){
            width: calc(100% - 520px);
        }
        @include media(tb){
            width: 100%;
            margin-top: 20px;
        }
        .launch-ttl{
            font-family: $COPPERPLATE;
            text-align: center;
            margin-bottom: 20px;
            @include media(tb){
                font-size: 1.6rem;
            }
            @include media(sp){
                margin-bottom: 10px;
                font-size: 1.6rem;
            }
        }
        .launch-list{
            @include flex_space_between;
        }
        .launch-item{
            width: calc(33.33% - 10px);
            @include fontsize_small;
            margin-bottom: 20px;
            @include media(sp){
                width: 100%;
                margin-bottom: 10px;
            }
            a{
                display: block;
                position: relative;
                color: $BLACK_COLOR;
                height: 160px;
                @include media(hd){
                    height: 120px;
                }
                @include media(sp){
                    height: 140px;
                }
                &[target="_blank"]:after{
                    content: '';
                    display: none;
                }
                &:hover{
                    opacity: 1;
                    .txt{
                        background: rgba(0,0,0,0);
                    }
                }
            }
            .img{
                overflow: hidden;
                position: relative;
                height: 100%;
            }
            .txt{
                //margin-top: 5px;
                //line-height: 1.2;
                font-family: $COPPERPLATE;
                @include flex_inner_center;
                width: 100%;
                height: 100%;
                pointer-events: none;
                color: $WHITE_COLOR;
                background: rgba(0,0,0,0.5);
                position: absolute;
                top: 0;
                text-align: center;
                line-height: 1.4;
                @include media(sp){
                    font-size: 1.4rem;
                }
            }
        }
    }

    .footer-copyright{
        padding: 20px 0;
        a{
            color: $BLACK_COLOR;
        }
        .footer-copyright-wrap{
            @include media(tb) {
                display: block;
            }
        }
        p{
            text-align: right;
            font-size: 1.4rem;
            font-family: $COPPERPLATE;
            @include media(tb) {
                font-size: 1.2rem;
                margin-top: 20px;
                text-align: center;
            }
        }
    }


/*
    .footer-navi-sns{
        padding: 30px 0;
        border-top: 10px solid $LIGHT_GRAY_COLOR;
        border-bottom: 10px solid $LIGHT_GRAY_COLOR;
        @include media(tb) {
            padding: 30px 0;
        }
        .sns-ttl{
            font-family: $COPPERPLATE;
            font-weight: bold;
            font-size: 2.0rem;
            text-align: center;
            margin-bottom: 20px;
            &:before{
                content: "";
                display: inline-block;
                width: 1px;
                height: 30px;
                background: $BLACK_COLOR;
                transform: rotate(-30deg) translateY(10px);
                margin-right: 30px;
            }
            &:after{
                content: "";
                display: inline-block;
                width: 1px;
                height: 30px;
                background: $BLACK_COLOR;
                transform: rotate(30deg) translateY(10px);
                margin-left: 30px;
            }
            @include media(sp) {
                font-size: 1.6rem;
                margin-bottom: 10px;
            }
            @include media(narrow) {
                font-size: 1.4rem;
                margin-bottom: 10px;
            }
        }
        .sns-wrap{
            display: flex;
            justify-content: center;
            @include media(sp) {
                flex-wrap: wrap;
            }
        }
        .sns-sub-ttl{
            font-family: $COPPERPLATE;
        }
        .sns-list{
            display: flex;
        }
        .sns-item{
            margin: 0 10px;
            @include media(sp) {
                margin: 0 10px;
            }
            i{
                //position: absolute;
                font-size: 4.0rem;
                //margin-right: 5px;
                vertical-align: bottom;
                left: 0;
                //top: -4px;
                @include media(tb) {
                    font-size: 3.5rem;
                }
                @include media(narrow) {
                    font-size: 3.0rem;
                }
            }
        }
        a{
            color: $BLACK_COLOR;
            font-size: 1.6rem;
            position: relative;
            //padding-left: 35px;
        }
        a[target="_blank"]:after{
            display: none;
        }
    }
    .footer-navi{
        font-family: $COPPERPLATE;
        background: $WHITE_COLOR;
        padding: 40px 0;
        border-top: 10px solid $LIGHT_GRAY_COLOR;
        @include media(tb) {
            padding: 30px 0 20px;
        }
        a{
            color: $WHITE_COLOR;
        }
        .inner{
            @include flex_space_between;
            align-items: center;
            @include media(hd) {
                display: block;
            }
        }
        .footer-navi-logo{
            position: relative;
            text-align: center;
            @include media(hd) {
                img{
                    width: 140px;
                    margin: 0 auto 40px;
                }
            }
            @include media(sp) {
                img{
                    width: 120px;
                }
            }
            img{
                width: 160px;
                @include media(sp) {
                    width: 140px;
                    margin-bottom: 20px;
                }
            }
        }
        .footer-navi-wrap{
            display: flex;
            justify-content: flex-end;
            @include media(hd) {
                justify-content: space-between;
            }
            @include media(sp) {
                flex-direction: column;
            }
            .navi-list{
                margin-left: 60px;
                &:last-child{
                    @include media(sp) {
                        .navi-item{
                            margin-bottom: 0;
                        }
                    }
                }
                @include media(hd) {
                    margin-left: 0;
                }
                @include media(sp) {
                    margin-left: 0px;
                }
            }
            .navi-item{
                font-size: 1.6rem;
                margin-bottom: 20px;
                @include media(hd) {
                    font-size: 1.4rem;
                    margin-bottom: 20px;
                }
                @include media(sp) {
                    font-size: 1.6rem;
                    margin-bottom: 0px;
                }
                & > a{
                    display: block;
                    //border-bottom: 1px solid #8EA2C1;
                    margin-bottom: 5px;
                    //padding-bottom: 5px;
                    position: relative;
                    color: $BLACK_COLOR;
                    @include media(sp) {
                        margin-bottom: 0px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        padding-right: 20px;
                        border-bottom: 1px dotted $GRAY_COLOR;
                        &:after{
                            font-family: $FONT_AWESOME;
                            content: '\f054';
                            display: block;
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &[target="_blank"]:after{
                            //content: "\f08e";
                            content: "";
                        }
                    }
                }
            }
            .sub-navi-list{
                padding-left: 10px;
            }
            .sub-navi-item{
                opacity: 0.8;
                font-size: 1.5rem;
                @include media(hd) {
                    font-size: 1.4rem;
                }
                @include media(sp) {
                    width: 100%;
                    font-size: 1.4rem;
                    margin-top: 5px;
                }
                a{
                    display: block;
                    color: $GRAY_COLOR;
                    position: relative;
                    @include media(sp) {
                        margin-top: 0px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        padding-right: 20px;
                        &:after{
                            font-family: $FONT_AWESOME;
                            content: '\f054';
                            display: block;
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &[target="_blank"]:after{
                            //content: "\f08e";
                            content: "";
                        }
                    }
                }
            }
        }
    }
    .footer-copyright{
        background: $BLACK_COLOR;
        color: $WHITE_COLOR;
        padding: 20px 0;
        font-size: 1.4rem;
        a{
            color: $WHITE_COLOR;
        }
        .footer-copyright-wrap{
            display: flex;
            justify-content: space-between;;
            @include media(tb) {
                display: block;
            }
        }
        .link-list{
            display: flex;
            margin: 0 0px;
            @include media(tb) {
                justify-content: center;
            }
            @include media(sp){
                flex-direction: column;
                margin: 0 0px;
            }
        }
        .link-item{
            margin: 0 20px 0 0;
            @include media(sp){
                font-size: 1.2rem;
                margin: 0 0 10px;
            }
            a{
                &:before{
                    font-family: $FONT_AWESOME;
                    content: '\f054';
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
        p{
            font-size: 1.2rem;
            font-family: $COPPERPLATE;
            @include media(tb) {
                font-size: 1.0rem;
                margin-top: 10px;
                text-align: center;
            }
        }
    }
*/
}

.pagetop{
    position: fixed;
    z-index: 100;
    right: 0px;
    bottom: 0px;
    a{
        display: block;
        width: 60px;
        height: 60px;
        background: $BLACK_COLOR;
        color: $WHITE_COLOR;
        @include flex_inner_center;
        font-size: 2.0rem;
        @include media(sp){
            width: 50px;
            height: 50px;
            font-size: 2.0rem;
        }
    }
}