// -------------------------------------------------------------------
//  main.scss
// -------------------------------------------------------------------
.main {
    display: block;

    //padding-top: $HEADER_HEIGHT_PC;
    @include media(tb){
        background-position: center top -100px;
        padding-top: $HEADER_HEIGHT_TB;
    }
    @include media(sp){
        background-position: center top 240px;
        padding-top: $HEADER_HEIGHT_SP;
    }
}
.sec-page-ttl{
    position: relative;
    border: 20px solid $WHITE_COLOR;
    border-top-width: 80px;
    height: 600px;
    color: $WHITE_COLOR;
    overflow: hidden;
    @include media(tb){
        border: none;
        height: 400px;
    }
    @include media(sp){
        height: 300px;
    }
    .page-ttl-wrap{
        height: 100%;
    }
    .page-img{
        height: 100%;
        position: relative;
        background: $BLACK_COLOR;
        &:after{
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            //background: url(../images/07.png);
            background: rgba(0,0,0,0.3);
            top: 0;
        }
    }
    .page-txt{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        @include flex_inner_center;
        .page-ttl{
            text-align: center;
            small.en{
                font-size: 3.0rem;
                margin-bottom: 10px;
                @include media(tb){
                    font-size: 2.4rem;
                }
                @include media(sp){
                    font-size: 1.8rem;
                }
            }
            .en{
                line-height: 1;
                display: block;
                font-family: $COPPERPLATE;
                font-weight: 400;
                @include fontsize_huge;
            }
            .jp{
                margin-top: 20px;
                line-height: 1;
                display: block;
                font-weight: 400;
            }
        }
    }
    &.sec-page-ttl-mini{
        height: 400px;
        color: $BLACK_COLOR;
        @include media(tb){
            border: none;
            height: 200px;
        }
        @include media(sp){
            height: 100px;
        }
    }
}
.sec-content{
    margin-bottom: 160px;
    &:last-child{
        margin-bottom: 0px;
    }
    @include media(wide) {
        margin-bottom: 140px;
    }
    @include media(hd) {
        margin-bottom: 120px;
    }
    @include media(tb) {
        margin-bottom: 80px;
    }
    @include media(sp) {
        margin-bottom: 60px;
    }
    position: relative;
}
.sec-content{
    position: relative;
    margin-bottom: 160px;
    &:last-child{
        margin-bottom: 0px;
    }
    @include media(wide) {
        margin-bottom: 140px;
    }
    @include media(hd) {
        margin-bottom: 120px;
    }
    @include media(tb) {
        margin-bottom: 80px;
    }
    @include media(sp) {
        margin-bottom: 60px;
    }
}
.sec-sub-content{
    position: relative;
    margin-bottom: 80px;
    &:last-child{
        margin-bottom: 0px;
    }
    @include media(wide) {
        margin-bottom: 70px;
    }
    @include media(hd) {
        margin-bottom: 60px;
    }
    @include media(tb) {
        margin-bottom: 40px;
    }
    @include media(sp) {
        margin-bottom: 30px;
    }
}
.sec-ttl{
    margin-bottom: 60px;
    @include media(tb) {
        margin-bottom: 40px;
    }
    @include media(sp) {
        margin-bottom: 20px;
    }
    .en{
        line-height: 1;
        display: block;
        font-family: $COPPERPLATE;
        font-weight: 400;
        @include fontsize_huge;
    }
    .jp{
        line-height: 1;
        display: block;
        font-weight: 400;
    }
}

.sec-sub-ttl{
    margin-bottom: 30px;
    @include media(tb) {
        margin-bottom: 20px;
    }
    @include media(sp) {
        margin-bottom: 20px;
    }
    font-weight: 600;
    @include fontsize_large;
}

.txt-des{
    margin-bottom: 40px;
    &:last-child{
        margin-bottom: 0px;
    }
    @include media(tb) {
        margin-bottom: 30px;
    }
    @include media(sp) {
        margin-bottom: 20px;
    }
}
.en{
    font-family: $COPPERPLATE;
}
.bg{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    .img-wrap{
        width: 40%;
        height: 100%;
        overflow: hidden;
        position: relative;
        @include media(tb) {
            width: 50%;
        }
        @include media(sp) {
            width: calc(100% - 20px);
        }
    }
}
.content-container{
    width: 62%;
    padding: 40px;
    background: rgba(255,255,255,0.9);
    @include media(tb) {
        width: 70%;
    }
    @include media(sp) {
        width: 100%;
        padding: 20px;
        margin: 0 !important;
    }
}
.img-left{
    .bg{
        justify-content: flex-start;
        @include media(sp) {
            height: 350px;
        }
        .img-wrap{
            .mask-inner{
                clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
            }
        }
    }
    .content-inner{
        display: flex;
        justify-content: flex-end;
        @include media(sp) {
            display: block;
            padding-top: 250px;
            margin-right: -20px;
        }
    }
    .content-container{
        margin-top: 20%;
        margin-bottom: 10%;
    }
}
.img-right{
    .bg{
        justify-content: flex-end;
        @include media(sp) {
            height: 350px;
        }
         .img-wrap{
            .mask-inner{
                clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
                @include media(sp) {
                    clip-path: polygon(0% 0, 100% 0, 100% 100%, 30% 100%);
                }
            }
        }
    }
    .content-inner{
        display: flex;
        justify-content: flex-start;
        @include media(sp) {
            display: block;
            padding-top: 250px;
            margin-left: -20px;
        }
    }
    .content-container{
        margin-top: 10%;
        margin-bottom: 20%;
    }
}


.nav-local{
    .nav-local-list{
        @include flex_space_between;
    }
    .nav-local-item{
        width: calc(25% - 10px);
        @include media(sp) {
            width: calc(50% - 5px);
            height: 150px;
            margin-bottom: 10px;
        }
        a{
            height: 100%;
            display: block;
            position: relative;
            color: $WHITE_COLOR;
            &:hover{
                opacity: 1;
                .txt{
                    background: rgba(0,0,0,0);
                }
            }
        }
        .img{
            overflow: hidden;
            height: 100%;
        }
        .txt{
            transition: background 0.5s ease;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            @include flex_inner_center;
            flex-direction: column;
            background: rgba(0,0,0,0.4);
            .en{
                display: block;
                font-family: $COPPERPLATE;
                @include fontsize_large;
                line-height: 1;
            }
            .jp{
                display: block;
                line-height: 1;
                margin-top: 10px;
            }
        }
    }
    .nav-another-list{
        @include flex_space_between;
        margin-top: 60px;
        @include media(tb) {
            margin-top: 40px;
        }
        @include media(sp) {
            margin-top: 10px;
        }
    }
    .nav-another-item{
        width: calc(50% - 10px);
        text-align: center;
        @include media(sp) {
            width: 100%;
            margin-bottom: 20px;
        }
        .en{
            font-family: $COPPERPLATE;
            @include fontsize_large;
            line-height: 1;
            margin-bottom: 10px;
        }
        .btn-wrap{
            margin: 0 auto;
            a{
                display: flex;
                width: 100%;
                max-width: 400px;
                margin: 0 auto;
            }
        }
    }
}

// ----------------------------------
//  .ir-list
// ----------------------------------
.ir-list{
    background: $WHITE_COLOR;
    padding: 40px 40px;
    margin-top: 20px;
    @include media(tb) {
        padding: 20px 0;
    }
    @include media(sp) {
        padding: 0px 0;
    }
}
.ir-item > span,
.ir-item > a{
    color: $BLACK_COLOR;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px 15px 20px;
    transition: background 0.5s ease;
    @include media(sp) {
        padding: 10px 10px;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &.ico-pdf{
        position: relative;
        padding: 15px 45px 15px 20px;
        @include media(sp) {
            padding: 15px 40px 15px 20px;
        }
        &:after{
            background: none;
            font-family: $FONT_AWESOME;
            content: "\f1c1";
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 2.8rem;
            @include media(tb) {
                font-size: 2.4rem;
            }
            @include media(sp) {
                top: auto;
                top: 40px;
                right: 20px;
                font-size: 1.8rem;
                transform: translateY(0%);
            }
        }
        .ir-ttl{
            @include media(sp) {
                padding-right: 10px;
            }
        }
    }
    .ir-date{
        width: 100px;
        flex-shrink: 0;
        font-size: 1.6rem;
        text-align: left;
        @include media(tb) {
            font-size: 1.4rem;
        }
        @include media(sp) {
            font-size: 1.2rem;
        }
    }
    .ir-ttl{
        width: calc(100% - 100px);
        font-size: 1.6rem;
        font-weight: normal;
        display: flex;
        @include media(tb) {
            font-size: 1.4rem;
        }
        @include media(sp) {
            width: 100%;
            font-size: 1.2rem;
            padding-top: 5px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    .ir-category{
        width: 120px;
        margin-top: 2px;
        margin-right: 20px;
        height: 24px;
        line-height: 24px;
        flex-shrink: 0;
        text-align: center;
        background: $BLACK_COLOR;
        color: $WHITE_COLOR;
        padding: 0px 5px;
        font-size: 1.2rem;
        @include media(sp) {
            position: absolute;
            right: 20px;
            top: 15px;
            width: 100px;
            height: 20px;
            line-height: 20px;
            font-size: 1.0rem;
            margin-top: 0px;
            margin-right: 0px;
        }
    }
    .ir-txt{
        width: auto;
        text-align: left;
    }
    .ir-des{
        width: 100%;
        font-size: 1.4rem;
        padding-left: 100px;
        padding-top: 10px;
        @include media(sp) {
            padding-left: 0px;
            padding-top: 5px;
            font-size: 1.2rem;
        }
    }
}
.ir-item > a{
    &:hover{
        background: $LIGHT_GRAY_COLOR;
        opacity: 1;
    }
}


// ----------------------------------
//  .sec-txt
// ----------------------------------
.sec-txt-lead{
    position: relative;
    margin-bottom: 80px;
    &:last-child{
        margin-bottom: 0px;
    }
    @include media(wide) {
        margin-bottom: 70px;
    }
    @include media(hd) {
        margin-bottom: 60px;
    }
    @include media(tb) {
        margin-bottom: 40px;
    }
    @include media(sp) {
        margin-bottom: 30px;
    }
    .content-inner{
        max-width: $SITE_WIDTH2;
        margin: 0 auto;
    }
    .txt-des{
        text-align: center;
        @include media(tb) {
            text-align: left;
        }
    }
}
.sec-txt{
    .content-inner{
        max-width: $SITE_WIDTH2;
        margin: 0 auto;
    }
    .sec-txt-ttl{
        @include fontsize_large;
        border-bottom: 1px dotted $GRAY_COLOR;
        padding-bottom: 10px;
        margin-bottom: 20px;
        @include media(sp) {
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
    }
    .note{
        padding: 40px;
        background: $OFF_WHITE_COLOR;
        @include media(tb) {
            padding: 20px;
        }
        margin-bottom: 40px;
        &:last-child{
            margin-bottom: 0px;
        }
        @include media(tb) {
            margin-bottom: 30px;
        }
        @include media(sp) {
            margin-bottom: 20px;
        }
    }
    .note-ttl{
        border-bottom: 1px dotted $GRAY_COLOR;
        padding-bottom: 10px;
        margin-bottom: 20px;
        @include media(sp) {
            padding-bottom: 5px;
            margin-bottom: 10px;
        }
    }
    .note-list{
        padding-left: 40px;
        @include media(tb) {
            padding-left: 20px;
        }
    }
    .note-item{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    ol .note-item{
        list-style: decimal outside;
    }
    ul .note-item{
        list-style: disc outside;
    }
}
