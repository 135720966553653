// -------------------------------------------------------------------
//  header.scss
// -------------------------------------------------------------------
.header#header {
    z-index: 300;
    position: relative;
    height: 80px;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    transition: all 0.35s ease;
    z-index: 100;
    width: 100%;
    background: $WHITE_COLOR;
    position: fixed;
    &.trans{
        //background: rgba(255,255,255,0.8) !important;
    }
    @include media(pc){
        height: $HEADER_HEIGHT_PC;
    }
    @include media(tb){
        height: $HEADER_HEIGHT_TB;
        //background: $WHITE_COLOR;
        &:hover{
            //background: $WHITE_COLOR;
        }
    }
    @include media(sp){
        height: $HEADER_HEIGHT_SP;
    }

    #header_logo{
        margin-left:40px;
        @include media(hd){
            margin-left:20px;
        }
        @include media(sp){
            margin-left: 20px;
            margin-right: 20px;
        }
        @include media(narrow){
            margin-left: 20px;
            margin-right: 20px;
        }
        img{
            width: 180px;
            @include media(tb){
                width: 160px;
            }
            @include media(sp){
                width: 160px;
            }
            @include media(narrow){
                width: 160px;
            }
        }
    }
    #global_menu{
        @include media(tb){
            display: none;
        }
        margin-right: 20px;
        @include media(hd){
            margin-right: 10px;
        }
        height: 100%;
        .menu-list{
            display: flex;
            height: 100%;
        }
        .menu-item{
            height: 100%;
            @include flex_inner_center;
            position: relative;
            & > a{
                color: $BLACK_COLOR;
                display: block;
                padding: 5px 10px;
                margin: 0 10px;
                font-weight: bold;
                font-size: 1.5rem;
                @include media(wide){
                    font-size: 1.4rem;
                    margin: 0 5px;
                }
                @include media(hd){
                    padding: 5px 10px;
                    margin: 0 0px;
                    font-size: 1.3rem;
                }
                &.is-selected{
                    border-bottom: 1px solid $BLACK_COLOR;
                }
                &.has-child:after{
                    font-family: $FONT_AWESOME;
                    font-weight: 100;
                    font-size: 1.4rem;
                    content: '\f078';
                    margin-left: 10px;
                }
                &.has-child.is-open{
                    &:after{
                        content: '\f077';
                    }
                    & + .sub-menu-list{
                        //height: auto;
                    }
                }
            }
            .en{
                display: none;
            }
        }
        .sub-menu-list{
            overflow: hidden;
            height: 0;
            position: absolute;
            top: $HEADER_HEIGHT_PC;
            left: 0;
            width: 250px;
            transition: height 0.5s ease;
        }
        .sub-menu-item{
            background: #FCFCFC;
            a{
                color: $BLACK_COLOR;
                display: block;
                font-size: 1.4rem;
                padding: 10px 20px;
                position: relative;
                &:after{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                    font-family: $FONT_AWESOME;
                    font-size: 1.2rem;
                    width: 16px;
                    height: 16px;
                    text-align: center;
                }
                &.is-disabled{
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
            a:not([target="_blank"]){
                &:after{
                    content: "\f054";
                }
            }
        }
    }
    #menu_button{
        @include media(pc){
            display: none;
        }
        transition: all 0.35s ease;
        display:block;
        font-size:11px;
        width:80px;
        height:80px;
        line-height:80px;
        text-decoration:none;
        text-align:center;
        position:relative;
        background: $BLACK_COLOR;
        &:hover{
            opacity: 1;
        }
        span{
            width:22px;
            background:#fff;
            display:block;
            position:absolute;
            transition: all 0.3s ease-in-out;
            height: 1px;
            left:30px;
            &:nth-child(1){
                top: 30px;
            }
            &:nth-child(2){
                width: 16px;
            }
            &:nth-child(3){
                top: 48px;
            }
            @include media(tb){
                left: 25px;
                &:nth-child(1){
                    top: 25px;
                }
                &:nth-child(2){
                    top: 35px;
                }
                &:nth-child(3){
                    top: 45px;
                }
            }
        }
        @include media(tb){
            width: $HEADER_HEIGHT_TB;
            height: $HEADER_HEIGHT_TB;
            line-height: $HEADER_HEIGHT_TB;
        }
    }
    .inner{
        @include flex_space_between;
    }
}

html.open_menu{
    .header#header{
        #menu_button{
            span:nth-child(1) {
                top:40px; transform: rotate(45deg);
                @include media(tb){
                    top: 35px;
                }
            }
            span:nth-child(2) {
                transform:scaleX(0);
            }
            span:nth-child(3) {
                top:40px; transform:rotate(135deg);
                @include media(tb){
                    top: 35px;
                }
            }
        }
    }
}
