// ----------------------------------
//  modal
// ----------------------------------
.modal-content{
    display:none;
    position: relative;
    z-index: 100;
    .modal-bg{
        content: "";
        position:fixed;
        left:0;
        top:0;
        height:100%;
        width:100%;
        background: rgba(0,0,0,0.8);
    }
    .modal-inner{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 900px;
        margin: 0 auto;
        z-index: 100;
        background: $WHITE_COLOR;
        border-radius: 10px;
            padding: 40px 35px 40px 40px;
        max-height: calc(100vh - 100px);

        // SP -----------------
        @include media(sp){
            padding: 40px 15px 40px 20px;
        }
    }
    .modal-inner .scroll{
        max-height: calc(100vh - 300px);
        overflow: auto;
    }

    .btn-close{
        cursor: pointer;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
        i{
            font-size: 1.8rem;
            vertical-align: top;
            &:before{
                @include flex_inner_center;
                width: 20px;
                height: 20px;
            }
        }
    }
}
