// -------------------------------------------------------------------
//  groupcompany.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}




// ----------------------------------
//  groupcompany
// ----------------------------------
.groupcompany{
    .inner{
        max-width: 1280px;
    }
    .sec-lead{
        .content-inner{
            max-width: 1000px;
            margin: 0 auto;
            text-align: center;
        }
        .sec-ttl{
            .en{
                @include media(sp) {
                    font-size: 2.4rem;
                }
            }
        }
    }
    .sec-company{
        .sec-sub-ttl{
            text-align: center;
        }
        .company-list{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        .company-item{
            width: calc(33.33% - 20px);
            margin: 0 10px 20px;
            border: 1px solid $GRAY_COLOR;
            position: relative;
            @include media(tb) {
                width: calc(50% - 10px);
                margin: 0 5px 10px;
            }
            @include media(sp) {
                width: calc(50% - 10px);
                margin: 0 5px 10px;
            }
            &:after{
                display: block;
                content: "";
                padding-top: 100%;
            }
            a{
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                //pointer-events: none;
                padding: 60px 20px;
                color: $BLACK_COLOR;
                @include flex_inner_center;
                flex-direction: column;
                height: 100%;
                @include media(tb) {
                    padding: 40px 10px;
                }
                @include media(sp) {
                    padding: 20px 10px;
                }
                @include media(pc) {
                    &:hover{
                        background: $BLACK_COLOR;
                        opacity: 1;
                        .logo{
                            img{
                                filter: invert(1);
                            }
                        }
                        .en{
                            color: $WHITE_COLOR;
                        }
                        .jp{
                            color: $WHITE_COLOR;
                        }
                        .image{
                            opacity: 0.5;
                        }
                    }
                }
            }
            .logo{
                position: relative;
                z-index: 2;
                margin-bottom: 20px;
                width: 240px;
                height: 80px;
                @include flex_inner_center;
                @include media(hd) {
                    width: 210px;
                    height: 70px;
                    margin-bottom: 20px;
                }
                @include media(sp) {
                    width: 90px;
                    height: 30px;
                    margin-bottom: 20px;
                }
                img{
                    transition: all 1.0s ease;
                }
            }
            .en{
                transition: all 1.0s ease;
                position: relative;
                z-index: 2;
                text-align: center;
                display: block;
                line-height: 1.4;
                margin-bottom: 10px;
                @include media(sp) {
                    font-size: 1.0rem;
                    margin-bottom: 2px;
                }
            }
            .jp{
                transition: all 1.0s ease;
                position: relative;
                z-index: 2;
                text-align: center;
                display: block;
                line-height: 1.4;
                @include fontsize_small;
            }
            .image{
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                background: $BLACK_COLOR;
                transition: all 1.0s ease;
            }
        }
    }
    .sec-independence{
        background: $LIGHT_GRAY_COLOR;
        padding: 40px 0 80px;
        @include media(tb) {
            padding: 20px 0 40px;
        }
        @include media(sp) {
            padding: 10px 0 20px;
        }
        &:before{
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 150px 100vw;
            border-color: transparent transparent $LIGHT_GRAY_COLOR transparent;
            position: absolute;
            top: -150px;
            @include media(wide) {
                border-width: 0 0 120px 100vw;
                top: -120px;
            }
            @include media(hd) {
                border-width: 0 0 100px 100vw;
                top: -100px;
            }
            @include media(tb) {
                border-width: 0 0 80px 100vw;
                top: -80px;
            }
            @include media(sp) {
                border-width: 0 0 40px 100vw;
                top: -40px;
            }
        }
        .sec-ttl{
            position: absolute;
            text-align: left;
            @include fontsize_large;
            top: -80px;
            @include media(tb) {
                top: -40px;
            }
            @include media(sp) {
                top: -40px;
                text-align: center;
                left: 0;
                right: 0;
            }
            span{
                font-size: 1.1em;
                @include media(sp) {
                }
            }
        }
    }
    .sec-restaurant{
        .sec-ttl{
            .en{
                font-size: 6.0rem;
                @include media(wide) {
                    font-size: 5.0rem;
                }
                @include media(hd) {
                    font-size: 3.4rem;
                }
                @include media(tb) {
                    font-size: 3.0rem;
                }
                @include media(sp) {
                    font-size: 2.2rem;
                }
            }
        }
    }
    .sec-shop{
        .inner{
            max-width: 1280px;
        }
        .sec-ttl{
            text-align: center;
            .en{
                font-size: 6.0rem;
                @include media(tb) {
                    font-size: 4.0rem;
                }
                @include media(sp) {
                    font-size: 3.0rem;
                }
            }
        }
        .shop-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            &:after{
                content: "";
                display: block;
                width: calc(33.33% - 20px);
            }
        }
        .shop-item{
            width: calc(33.33% - 20px);
            margin-bottom: 40px;
            @include media(sp) {
                width: calc(50.0% - 10px);
            }
            a{
                text-align: center;
                display: block;
                color: $BLACK_COLOR;
                span{
                    display: block;
                }
                .image{
                    height: 200px;
                    @include media(tb) {
                        height: 160px;
                    }
                    @include media(sp) {
                        height: 100px;
                    }
                }
                .area{
                    margin-top: 10px;
                    line-height: 1.2;
                }
                .name{
                    margin-top: 5px;
                    line-height: 1.2;
                }
                .name:after{
                    width: 16px;
                    height: 16px;
                    content: "";
                    display: inline-block;
                    background: url(../images/ico_window_bk.svg) no-repeat center center;
                    background-size: contain;
                    margin-left: 5px;
                    vertical-align: middle;
                }
                &:after{
                    display: none;
                }
            }
        }
    }
    .sec-company-data{
        .inner{
            max-width: 1280px;
        }
        .sec-ttl{
            text-align: center;
            .en{
                font-size: 6.0rem;
                @include media(tb) {
                    font-size: 4.0rem;
                }
                @include media(sp) {
                    font-size: 3.0rem;
                }
            }
        }
        .company-copy{
            text-align: center;
            @include fontsize_large;
            margin-bottom: 40px;
        }
        .company-data{
            max-width: 660px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            dt{
                width: 120px;
                @include media(tb) {
                    width: 80px;
                }
            }
            dd{
                width: calc(100% - 160px);
                margin-bottom: 20px;
                @include media(tb) {
                    width: calc(100% - 100px);
                }
            }
        }
    }
}
