// -------------------------------------------------------------------
//  variables.scss
// -------------------------------------------------------------------

// Width
// -------------------------------------------------------------------
$SITE_WIDTH: 1600px;
$SITE_WIDTH2: 1280px;
$BREAK_POINT_SP_1: 360px;
$BREAK_POINT_SP_2: 660px;
$BREAK_POINT_TB: 1060px;
$BREAK_POINT_PC_1: 1280px;
$BREAK_POINT_PC_2: 1600px;

// Header Height
// -------------------------------------------------------------------
$HEADER_HEIGHT_PC: 80px;
$HEADER_HEIGHT_TB: 70px;
$HEADER_HEIGHT_SP: 70px;

// Font
// -------------------------------------------------------------------
$BASE_FONT_SIZE: 16;
$BASE_FONT_SIZE_WIDE: 15;
$BASE_FONT_SIZE_HD: 15;
$BASE_FONT_SIZE_TB: 14;
$BASE_FONT_SIZE_SP: 12;
$BASE_LINE_HEIGHT: 2.0;
$BASE_FONT_FAMILY:  '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
$EN_FONT_FAMILY: sans-serif;
$FONT_AWESOME: "Font Awesome 5 Pro";

// Web Font
// -------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600&display=swap');
$MINCHO: 'Shippori Mincho', serif;
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600&display=swap');
$SERIF: 'Crimson Text', serif;
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP);
$NOTO_SANS_JP: 'Noto Sans JP', sans-serif;
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
$OPEN_SANS: 'Open Sans', sans-serif;
@import url(https://use.typekit.net/yif8yxk.css);
$COPPERPLATE: copperplate, serif;


// Color
// -------------------------------------------------------------------
$BORDER_COLOR: #CCC;
$BASE_BG_COLOR: #F2F2F2;
$BASE_TEXT_COLOR: #2c2c2c;
$WHITE_COLOR: #FFFFFF;
$OFF_WHITE_COLOR: #F7F7F7;
$DARK_GRAY_COLOR: #494949;
$GRAY_COLOR: #9F9F9F;
$LIGHT_GRAY_COLOR: #EEE;


$BLACK_COLOR: #000;
$LIGHT_BLUE_COLOR: #4985CB;
$BLUE_COLOR: #214281;
$RED_COLOR: #BC1313;





$FOOTER_COLOR: #F0F5FA;

// Link Color
// -------------------------------------------------------------------
$LINK_COLOR: #918D8D;
$LINK_HOVER_COLOR: #918D8D;
$LINK_VISITED_COLOR: #918D8D;
$LINK_ACTIVE_COLOR: #918D8D;

// Image Path
// -------------------------------------------------------------------
$IMAGE_DIR: '../images/';
