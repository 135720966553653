// -------------------------------------------------------------------
//  socialmediapolicy.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}

// ----------------------------------
//  socialmediapolicy
// ----------------------------------
.socialmediapolicy{
    @include media(sp) {
        .sec-page-ttl .page-txt .page-ttl .en{
            font-size: 2.6rem;
        }
    }
    .content-inner{
        max-width: 1000px;
        margin: 0 auto;
    }
    .sns-container{
        a{
            color: $BLACK_COLOR;
            &[target="_blank"]:after{
                content: "";
                display: none;
            }
        }
        padding: 40px;
        background: $OFF_WHITE_COLOR;
        @include media(tb) {
            padding: 20px;
        }
        .company-official{
            @include flex_space_between;
            border-bottom: 1px solid $GRAY_COLOR;
            padding-bottom: 10px;
            margin-bottom: 40px;
            @include media(tb) {
                margin-bottom: 30px;
            }
            @include media(sp) {
                margin-bottom: 20px;
            }
        }
        .company-logo{
            width: 220px;
            @include media(tb) {
                width: 180px;
            }
            @include media(sp) {
                width: 120px;
            }
        }
        .company-sns{
            margin-left: 40px;
            .sns-list{
                display: flex;
                justify-content: flex-end;
                flex-direction: row-reverse;
            }
            .sns-item{
                margin-left: 5px;
                i{
                    font-size: 3.0rem;
                    @include media(sp) {
                        font-size: 2.0rem;
                    }
                }
            }
        }
        .shop-sns{
            .shop-list{
                @include flex_space_between;
            }
            .shop-item{
                width: calc(50% - 30px);
                margin-bottom: 20px;
                @include flex_space_between;
                border-bottom: 1px dotted $GRAY_COLOR;
                align-items: center;
                padding-bottom: 5px;
                @include media(hd) {
                    width: calc(50% - 20px);
                }
                @include media(sp) {
                    width: 100%;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .shop-name{
                    font-size: 0.8em;
                    width: calc(100% - 140px);
                    line-height: 1.2;
                    @include media(tb) {
                        width: calc(100% - 120px);
                    }
                    @include media(sp) {
                        width: calc(100% - 110px);
                    }
                    a{
                        text-decoration: underline;
                    }
                }
                .sns-list{
                    width: 130px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row-reverse;
                    @include media(tb) {
                        width: 120px;
                    }
                    @include media(sp) {
                        width: 100px;
                    }
                }
                .sns-item{
                    margin-left: 5px;
                    a{
                        display: block;
                        height: 25px;
                        @include media(tb) {
                            height: 25px;
                        }
                        @include media(sp) {
                            height: 20px;
                        }
                    }
                    i{
                        font-size: 2.4rem;
                        @include media(sp) {
                            font-size: 2.0rem;
                        }
                    }
                }
            }
        }
    }
}
