// -------------------------------------------------------------------
//  sustainability.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}



// ----------------------------------
//  sustainability
// ----------------------------------
.sustainability{
    .sec-content{
        .sec-ttl{
            font-size: 3.0rem;
            border-bottom: 1px dotted $BLACK_COLOR;
            margin-bottom: 30px;
            padding: 10px;
            @include media(tb) {
                font-size: 2.4rem;
                margin-bottom: 20px;
            }
            @include media(sp) {
                font-size: 1.6rem;
                margin-bottom: 10px;
                padding: 10px 0;
            }
        }
    }
    .sec-sub-content{
        padding: 0 80px;
        margin: 80px 0 0;
        @include media(wide) {
            padding: 0 40px;
            margin: 60px 0 0;
        }
        @include media(hd) {
            padding: 0 20px;
            margin: 40px 0 0;
        }
        @include media(tb) {
            padding: 0 0px;
            margin: 30px 0 0;
        }
        @include media(sp) {
            padding: 0 0px;
            margin: 20px 0 0;
        }
        .sec-sub-ttl{
            display: inline-block;
            font-size: 2.6rem;
            border-bottom: 1px dotted $BLACK_COLOR;
            margin-bottom: 30px;
            padding: 10px;
            @include media(tb) {
                font-size: 2.0rem;
                margin-bottom: 20px;
            }
            @include media(sp) {
                font-size: 1.4rem;
                padding: 10px 0;
                margin-bottom: 10px;
            }
        }
    }
    .sec-sustainability-news{
        .news-item{
            display: flex;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
            time{
                display: block;
                width: 150px;
                @include media(sp) {
                    width: 100px;
                }
            }
            p{
                width: calc(100% - 150px);
                @include media(sp) {
                    width: calc(100% - 100px);
                }
            }
            a{
                color: $BASE_TEXT_COLOR;
                text-decoration: underline;
            }
        }
    }
    .sec-sustainability-attempt{
        .attempt-list{
            margin: 40px auto;
            max-width: 1000px;
            padding-left: 20px;
        }
        .attempt-item{
            font-weight: bold;
            list-style: decimal outside;
            margin-bottom: 10px;
            padding-left: 10px;
        }
        picture{
            display: block;
            margin: 40px auto 0;
            max-width: 1000px;
            text-align: center;
        }
    }
    .sec-sustainability-materiality{
        picture{
            display: block;
            margin: 40px auto 0;
            max-width: 1000px;
            text-align: center;
        }
        .sec-sub-content{
            .sec-sub-ttl{
                border: none;
                margin: 0;
                font-size: 2.0rem;
                .en{
                    margin-right: 20px;
                }
            }
            .materiality-list{
                border: 1px solid $BORDER_COLOR;
                padding: 0 40px;
                @include media(tb) {
                    padding: 0 30px;
                }
                @include media(sp) {
                    padding: 0 20px;
                }
            }
            .materiality-item{
                padding: 40px 20px;
                border-bottom: 1px solid $BORDER_COLOR;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                @include media(hd) {
                    padding: 40px 0;
                }
                @include media(tb) {
                    padding: 20px 0;
                }
                .materiality-ttl{
                    width: 250px;
                    position: relative;
                    padding-left: 30px;
                    @include media(hd) {
                        width: 250px;
                    }
                    @include media(tb) {
                        width: 100%;
                    }
                    @include media(sp) {
                        font-size: 1.3rem;
                    }
                    .num{
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        font-size: 1.2rem;
                        border-radius: 50%;
                        color: $WHITE_COLOR;
                        text-align: center;
                        position: absolute;
                        left: 0;
                        top: 6px;
                        @include media(sp) {
                            top: 2px;
                        }
                    }
                }
                .materiality-txt{
                    padding: 0 40px;
                    width: calc(100% - 450px);
                    @include media(hd) {
                        width: calc(100% - 410px);
                    }
                    @include media(tb) {
                        width: 100%;
                        padding: 20px 0px;
                    }
                }
                .materiality-ico{
                    width: 200px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    @include media(hd) {
                        width: 160px;
                    }
                    @include media(tb) {
                        width: 100%;
                        justify-content: flex-start;
                    }
                    img{
                        width: 90px;
                        margin-bottom: 20px;
                        @include media(hd) {
                            width: 70px;
                        }
                        &:nth-last-child(1){
                            margin-bottom: 0px;
                        }
                        &:nth-last-child(2){
                            margin-bottom: 0px;
                        }
                        @include media(tb) {
                            width: 60px;
                            margin-bottom: 0px;
                            margin-right: 10px;
                        }
                    }
                }
                &:last-child{
                    border: none;
                }
            }
            &.green{
                .en{
                    color: #4F783D;
                }
                .num{
                    background: #4F783D;
                }
            }
            &.red{
                .en{
                    color: #B3282C;
                }
                .num{
                    background: #B3282C;
                }
            }
            &.blue{
                .en{
                    color: #2A4053;
                }
                .num{
                    background: #2A4053;
                }
            }
        }
    }
    .sec-sustainability-decarbonization{
        .sec-sub-sub-content{
            margin-bottom: 40px;
        }
        .sec-sub-sub-ttl{
            font-size: 2.0rem;
            font-weight: bold;
            margin-bottom: 20px;
            @include media(tb) {
                font-size: 1.6rem;
                margin-bottom: 10px;
            }
            @include media(sp) {
                font-size: 1.4rem;
                margin-bottom: 0px;
            }
        }
        .table-wrap{
            margin-bottom: 40px;
            @include media(tb) {
                margin: 0 -40px 30px;
                padding: 0 40px;
                overflow-x: auto;
            }
            @include media(sp) {
                margin: 0 -20px 20px;
                padding: 0 20px;
                overflow-x: auto;
            }
        }
        table{
            width: 100%;
            @include media(tb) {
                min-width: 900px;
            }
            th,td{
                border: 1px solid $BORDER_COLOR;
                padding: 5px 10px;
                font-size: 1.4rem;
                vertical-align: middle;
                &.center{
                    text-align: center;
                }
                @include media(tb) {
                    font-size: 1.3rem;
                }
                @include media(sp) {
                    font-size: 1.2rem;
                }
            }
            th.style-1{
                background: #D9E0F1;
            }
            th.style-2{
                background: #ACB8C9;
            }
        }
        p{
            margin-bottom: 20px;
        }
        .notes{
            font-size: 1.4rem;
            display: flex;
            margin-bottom: 20px;
            @include media(tb) {
                font-size: 1.2rem;
            }
            @include media(sp) {
                font-size: 1.0rem;
            }
            dt{
                width: 50px;
            }
        }
    }
}
