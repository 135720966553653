// -------------------------------------------------------------------
//  about.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}



// ----------------------------------
//  about
// ----------------------------------
.about{
    .sec-vision{
        .content-inner{
            max-width: 1000px;
            margin: 0 auto;
            text-align: center;
            .sec-ttl{
                margin-bottom: 0;
            }
        }
    }
}

// ----------------------------------
//  about-message
// ----------------------------------
.about-message{
    .sec-lead{
        text-align: center;
        @include media(sp) {
            .txt-des{
                font-size: 1.1em;
                text-align: left;
                br{
                    display: none;
                }
            }
        }
        .content-inner{
            max-width: 1000px;
            text-align: left;
            margin: 0 auto;
        }
        .sec-ttl{
            .jp{
                font-weight: bold;
                @include fontsize_large;
                @include media(sp) {
                    line-height: 1.6;
                }
            }
        }
        .signature-wrap{
            display: flex;
            justify-content: center;
        }
        .signature{
            text-align: center;
            @include fontsize_small;
            max-width: 300px;
            margin-top: 40px;
            @include media(tb) {
                margin-top: 20px;
            }
            @include media(sp) {
                margin-top: 10px;
                max-width: 200px;
            }
        }
    }
    .sec-profile{
        & > .sec-ttl{
            text-align: center;
        }
        .sec-member{
            .content-container{
                @include media(tb) {
                    max-width: 60%;
                }
                @include media(sp) {
                    max-width: 100%;
                }
            }
            &.img-left{
                .content-container{
                    @include media(tb) {
                    }
                }
            }
            &.img-right{
                .content-container{
                    @include media(tb) {
                    }
                }
            }
            .sec-ttl{
                .role{
                    margin-bottom: 10px;
                    display: block;
                }
                .jp{
                    @include fontsize_huge;
                    margin-bottom: 20px;
                }
                .en{
                    @include fontsize_normal;
                    span{
                        font-size: 1.2em;
                    }
                }
            }
            .txt-des{
                @include media(sp) {
                    br{
                        display: none;
                    }
                }
            }
            .career-list{
                @include flex_space_between;
            }
            .career-term{
                width: 80px;
                @include media(tb) {
                    width: 60px;
                }
            }
            .career-des{
                width: calc(100% - 80px);
                @include media(tb) {
                    width: calc(100% - 60px);
                }
            }
        }
    }
}


// ----------------------------------
//  about-outline
// ----------------------------------
.about-outline{
    .sec-data{
        .content-inner{
            @include flex_space_between;
        }
        .sec-ttl{
            padding-top: 100px;
            width: calc(100% - 640px);
            @include media(hd) {
                padding-top: 0px;
                width: 100%;
            }
        }
        .data-list{
            @include flex_space_between;
            width: 640px;
            @include media(hd) {
                width: 100%;
            }
        }
        .data-term{
            width: 160px;
            @include media(sp) {
                width: 90px;
                font-size: 0.9em;
            }
        }
        .data-des{
            width: calc(100% - 160px);
            margin-bottom: 20px;
            animation-delay: 0.25s;
            @include media(tb) {
                margin-bottom: 20px;
            }
            @include media(sp) {
                margin-bottom: 20px;
                width: calc(100% - 90px);
                font-size: 0.9em;
            }
            p{
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    color: $BLACK_COLOR;
                    pointer-events: none;
                    @include media(sp) {
                        pointer-events: initial;
                        text-decoration: underline;
                    }
                }
                .caution{
                    display: block;
                    margin-top: 5px;
                    font-size: 1.2rem;
                    color: $RED_COLOR;
                    line-height: 1.4;
                    @include media(sp) {
                        font-size: 0.9rem;
                    }
                    a{
                        color: $RED_COLOR;
                        text-decoration: underline;
                        pointer-events: initial;
                    }
                }
            }
        }
    }
    a[target="_blank"]{
        &:after{
            width: 20px;
            height: 20px;
            background: url(../images/ico_window_gray.svg) no-repeat center center;
            background-size: contain;
            @include media(sp) {
                width: 16px;
                height: 16px;
            }
        }
    }

    .sec-osaka,
    .sec-tokyo,
    .sec-design{
        .content-container{
            margin-top: 3%;
            margin-bottom: 0;
        }
        .sec-ttl{
            margin-bottom: 30px;
            .en{
                line-height: 0.7;
                span{
                    font-size: 0.7em;
                    @include media(hd) {
                        font-size: 0.6em;
                    }
                    @include media(tb) {
                        font-size: 0.7em;
                    }
                }
            }
        }
        .sec-sub-ttl{
            margin-bottom: 10px;
        }
        .txt-des{
            a{
                color: $BLACK_COLOR;
                pointer-events: none;
                @include media(sp) {
                    pointer-events: initial;
                    text-decoration: underline;
                }
            }
            .caution{
                display: block;
                margin-top: 5px;
                font-size: 1.2rem;
                color: $RED_COLOR;
                line-height: 1.4;
                @include media(sp) {
                    font-size: 0.9rem;
                }
                a{
                    color: $RED_COLOR;
                    text-decoration: underline;
                    pointer-events: initial;
                }
            }
        }
        .map{
            iframe{
                width: 100%;
                height: 400px;
                @include media(sp) {
                    height: 300px;
                }
            }
        }
    }
    .sec-tokyo{
        .content-container{
            margin-top: 0%;
            margin-bottom: 3%;
        }
    }
}


// ----------------------------------
//  about-history
// ----------------------------------
.about-history{
    .sec-history{
        .inner{
            &:before{
                content: "";
                display: block;
                position: absolute;
                height: 100%;
                width: 1px;
                background: $GRAY_COLOR;
                left: 50%;
                top: 0;
                @include media(tb) {
                    left: 40px;
                }
                @include media(sp) {
                    left: 20px;
                }
            }
        }
        .history-list{
            position: relative;
            margin-bottom: 160px;
            @include media(tb) {
                margin-bottom: 80px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
        .history-list:nth-child(odd){
            .history-item{
                flex-direction: row;
                .txt{
                    text-align: right;
                    padding-right: 80px;
                }
                .img-list{
                    padding-left: 80px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
                .img-item{
                    width: calc(50% - 10px);
                    height: 200px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    overflow: hidden;
                }
            }
        }
        .history-list:nth-child(even){
            .history-item{
                flex-direction: row-reverse;
                .txt{
                    text-align: left;
                    padding-left: 80px;
                }
                .img-list{
                    padding-right: 80px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }
                .img-item{
                    width: calc(50% - 10px);
                    height: 200px;
                    margin-left: 10px;
                    margin-bottom: 10px;
                    overflow: hidden;
                }
            }
        }
        .history-item{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 80px;
            position: relative;
            &:after{
                content: "";
                display: block;
                position: absolute;
                height: 40px;
                width: 40px;
                background: #707070;
                left: 50%;
                top: 20px;
                transform: translateX(-50%);
                border-radius: 50%;
                @include media(tb) {
                    top: 5px;
                    left: -15px;
                    transform: translateX(0%);
                    height: 30px;
                    width: 30px;
                }
                @include media(sp) {
                    top: 5px;
                    left: -10px;
                    transform: translateX(0%);
                    height: 20px;
                    width: 20px;
                }
            }
            a{
                color: $BLACK_COLOR;
                text-decoration: underline;
                &:after{
                    display: none;
                }
            }
            @include media(tb) {
                display: block;
                padding-left: 40px;
                margin-bottom: 40px;
                .txt{
                    text-align: left !important;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    width: 100% !important;
                    margin-bottom: 20px;
                }
                .img-list{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    width: 100% !important;
                    justify-content: flex-start !important;
                    .img-item{
                        margin-left: 0 !important;
                        margin-right: 10px !important;
                    }
                }
            }
            @include media(sp) {
                padding-left: 20px;
                .txt{
                    margin-bottom: 10px;
                }
                .img-list{
                    .img-item{
                        height: 100px !important;
                    }
                }
            }
            .txt{
                width: 50%;
                .history-ttl{
                    line-height: 1;
                    font-family: $COPPERPLATE;
                    @include fontsize_huge;
                    font-weight: normal;
                }
                .year{
                }
                .month{
                    font-size: 0.7em;
                }
                .history-txt{
                    @include fontsize_small;
                }
            }
            .img-list{
                width: 50%;
            }
            &.close > *{
                opacity: 0.5;
            }
        }
    }
}