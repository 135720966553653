// ----------------------------------
//  button
// ----------------------------------

// ----------------------------------
//ボタンの共通定義
// ----------------------------------
%base-btn{
    @include flex_inner_center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 400px;
    //border-radius: 5px;
    overflow: hidden;
    &:before{
        position: absolute;
        top: 0;
        z-index: -1;
        display: block;
        content: '';
        transition: all .5s ease;
        left: -100%;
        width: 100%;
        height: 100%;
    }
    &:after{
        font-family: $FONT_AWESOME;
        content: '\f054';
        position: absolute;
        top: 50%;
        right: 10px;
        @include media(tb){
            right: 10px;
        }
        transform: translateY(-50%);
        color: $WHITE_COLOR;
    }
    i{
        position: absolute;
        right: 10px;
        @include media(tb){
            right: 10px;
        }
        top: 50%;
        transform: translateY(-50%);
        font-size: 1rem;
    }
    // PC -----------------
    @include media(pc){
        &:hover{
            opacity: 0.7;
        }
    }
    &.is-disabled{
        cursor: default;
    }
}

// ----------------------------------
//ボタンの色とhoverの定義
// ----------------------------------
%black-btn{
    color: $WHITE_COLOR !important;
    //background: linear-gradient(90deg, $GRAD_BLUE_1, $GRAD_BLUE_2);
    background: $BLACK_COLOR;
    border: none;
    // PC -----------------
    @include media(pc){
        &:hover{
            opacity: 1;
            &:before{
                background-color: $DARK_GRAY_COLOR;
                left: 0;
                opacity: 1;
            }
        }
    }
}
%disabled-btn{
    color: $GRAY_COLOR !important;
    background-color: $LIGHT_GRAY_COLOR;
    border: 1px solid $LIGHT_GRAY_COLOR;
    pointer-events: none;
    &:after{
        color: $GRAY_COLOR !important;
    }
    // PC -----------------
    @include media(pc){
        &:hover{
            color: $GRAY_COLOR !important;
            background-color: $LIGHT_GRAY_COLOR;
            border: 1px solid $LIGHT_GRAY_COLOR;
            opacity: 1;
        }
    }
}
// ----------------------------------
//ボタンサイズ（高さのみ）の定義
// ----------------------------------
%small-btn{
    height: 20px;
    font-size: 1.2rem;
    @include media(tb){
        font-size: 1.0rem;
    }
}
%midium-btn{
    height: 40px;
    font-size: 1.3rem;
    @include media(tb){
        font-size: 1.1rem;
    }
}
%large-btn{
    height: 60px;
    font-size: 1.8rem;
    @include media(tb){
        font-size: 1.4rem;
        height: 60px;
    }
    @include media(sp){
        font-size: 1.4rem;
        height: 50px;
    }
}
%huge-btn{
    height: 70px;
    font-size: 1.8rem;
    @include media(tb){
        font-size: 1.4rem;
        height: 60px;
    }
    @include media(sp){
        font-size: 1.4rem;
        height: 60px;
    }
}

// ----------------------------------
//具体的なボタンの定義
// ----------------------------------
.btn-black{
    @extend %base-btn;
    @extend %black-btn;
    @extend %large-btn;
    &.is-disabled{
        @extend %disabled-btn;
    }
}
.btn-submit{
    @extend %base-btn;
    @extend %black-btn;
    @extend %large-btn;
    &.is-disabled{
        @extend %disabled-btn;
    }
}


.btn-large{
    @extend %large-btn;
}
.btn-extend-wrap{
    .btn-more{
        opacity: 0;
    }
}


/*背景色が伸びて出現 共通*/
.bgextend{
    display: block;
    max-width: 400px;
    animation-name:bgextendAnimeBase;
    animation-duration:1s;
    animation-fill-mode:forwards;
    position: relative;
    overflow: hidden;/*　はみ出た色要素を隠す　*/
    opacity:0;
    @include media(sp) {
      margin: 0 auto;
    }
}

@keyframes bgextendAnimeBase{
  from {
    opacity:0;
  }

  to {
    opacity:1;  
}
}

/*中の要素*/
.bgappear{
    animation-name:bgextendAnimeSecond;
    animation-duration:1s;
    animation-delay: 0.6s;
    animation-fill-mode:forwards;
    opacity: 0;
}

@keyframes bgextendAnimeSecond{
  0% {
  opacity: 0;
  }
  100% {
  opacity: 1;
}
}

/*--------- 左から --------*/
.bgLRextendButton span{
    animation-name: bgLRextendButtonAnime;
    animation-duration: 1s;
    animation-fill-mode:forwards;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    //border-radius: 5px;
    left: 0;
    display: block;
    transform:scaleX(0);
    transform-origin:left;
}
.bgLRextendButton.black{
    span{
        background-color: $BLACK_COLOR;
    }
}
.bgLRextendButton.gray{
    span{
        background-color: $LIGHT_GRAY_COLOR;
    }
}
@keyframes bgLRextendButtonAnime{
  0% {
    transform:scaleX(0);
  }
  50% {
    transform:scaleX(1);
  }
  100% {
    transform:scaleX(1);
  }
}