// ----------------------------------
//  swiper
// ----------------------------------
.swiper{
    position: relative;
    margin-bottom: 2.6rem;
    @include media(sp){
        margin-right: -20px;
        margin-left: -20px;
    }
}
.swiper-button-next{
    background: none;
    @include media(pc){
        right: -40px;
    }
    @include media(tb){
        right: -20px;
    }
    @include media(sp){
        right: 10px;
    }
    &:after{
        @include flex_inner_center;
        font-family: $FONT_AWESOME;
        font-size: 5rem;
        color: $LINK_COLOR;
        content: "\f054";
        @include media(tb){
            font-size: 3rem;
        }
        @include media(sp){
            font-size: 2rem;
        }
    }
}
.swiper-button-prev{
    background: none;
    @include media(pc){
        left: -40px;
    }
    @include media(tb){
        left: -20px;
    }
    @include media(sp){
        left: 10px;
    }
    &:after{
        @include flex_inner_center;
        font-family: $FONT_AWESOME;
        font-size: 5rem;
        color: $LINK_COLOR;
        content: "\f053";
        @include media(tb){
            font-size: 3rem;
        }
        @include media(sp){
            font-size: 2rem;
        }
    }
}
.swiper-pagination{
    width: 100%;
    bottom: -2rem;
}
.swiper-pagination-bullet{
    margin: 0 0.5rem;
    width: 30px;
    height: 2px;
    border-radius: 0;
}
.swiper-pagination-bullet-active{
    background: $LINK_COLOR;
}


.swiper-slide{
    background: transparent;
    a{
        display: block;
        box-shadow: 0px 0px 5px $GRAY_COLOR;
        margin: 5px;
        color: $BASE_TEXT_COLOR;
        @include media(pc){
            &:hover{
                opacity: 1 !important;
                .img img{
                    transform: scale(1.1);
                }
            }
        }
    }
    .img{
        height: 20vw;
        max-height: 256px;//1280×0.2
        max-height: 360px;//1280×0.2
        overflow: hidden;
        @include flex_inner_center;

        @include media(sp){
            height: 40vw;
            max-height: inherit;
        }

        img{
            max-height: 100%;
        }
    }
    .txt{
        background: $WHITE_COLOR;
        padding: 10px 20px;
        @include media(sp){
            padding: 10px 10px;
        }
    }
}
