.breadcrumb{
    background: $OFF_WHITE_COLOR;
    margin-bottom: 80px;
    overflow-y: scroll;
    @include media(wide) {
        margin-bottom: 70px;
    }
    @include media(hd) {
        margin-bottom: 60px;
    }
    @include media(tb) {
        margin-bottom: 40px;
    }
    @include media(sp) {
        margin-bottom: 30px;
    }
    .inner{
        max-width: inherit;
        padding: 10px 40px;
        @include media(sp) {
            padding: 0px 20px;
        }
    }
    ul{
        padding-top: 10px;
        padding-bottom: 10px;
        //border-bottom: 1px dotted $GRAY_COLOR;
        display: flex;
    }
    li{
        @include fontsize_small;
        text-wrap: nowrap;
        &:after{
            content: "＞";
            display: inline-block;
            margin: 0 10px;
        }
        &:last-child{
            &:after{
                content: "";
            }
        }
    }
    a{
        text-decoration: underline;
        color: $BLACK_COLOR;
    }
}
