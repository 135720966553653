// -------------------------------------------------------------------
//  privacypolicy.scss
// -------------------------------------------------------------------
@include media(wide) {
}
@include media(hd) {
}
@include media(tb) {
}
@include media(sp) {
}
@include media(narrow) {
}

// ----------------------------------
//  privacypolicy
// ----------------------------------
.privacypolicy{
    .content-inner{
        max-width: 1000px;
        margin: 0 auto;
    }
}
